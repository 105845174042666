import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Box, Typography } from "@material-ui/core";
import {
  FullScreenOverlay,
  Button,
  Checkbox,
} from "@envestnet/envreact-component-library";
import FiltersDropdownList from "~/src/components/TradeExecutionSummary/FiltersDropdownList";
import style from "./style.module.css";

class FiltersOverlay extends Component {
  render() {
    return (
      <FullScreenOverlay
        open={this.props.TradeExecutionSummaryStore.IsFilterOverlayOpen}
      >
        <Box pl={"16.666667%"}>
          <div className={style.tradeExecutionFiltersWrapper}>
            <span>
              <i
                className={`far fa-times ${style.overlay_close_link}`}
                onClick={() =>
                  this.props.TradeExecutionSummaryStore.CloseFiltersOverlay()
                }
              ></i>
            </span>
            <Box>
              <i
                className={`far fa-3x fa-arrow-circle-right ${style.overlay_arrow_icon}`}
                onClick={() =>
                  this.props.TradeExecutionSummaryStore.CloseFiltersOverlay()
                }
              ></i>
              <Typography className={`${style.filtersHeading}`}>
                Filter your results
              </Typography>
              <Typography>
                You can manage the results you see by using the filters below to
                provide you with a more focused set of records.
              </Typography>
              <FiltersDropdownList />
              <Checkbox
                checked={
                  this.props.TradeExecutionSummaryStore
                    .IsSearchCriteriaSaveToLayout
                }
                label="Set current filters as default"
                onClick={() =>
                  this.props.TradeExecutionSummaryStore.SetIsSearchCriteriaSaveToLayout()
                }
                value={1}
              />
            </Box>
            <Box className={`${style.filtersButton}`}>
              <Button
                name={"Apply"}
                onClick={() =>
                  this.props.TradeExecutionSummaryStore.ApplySearchCriteriaDropDownValues()
                }
              />
              <Button
                name={"Cancel"}
                buttonStyle="secondary"
                onClick={() =>
                  this.props.TradeExecutionSummaryStore.CloseFiltersOverlay()
                }
              />
            </Box>
          </div>
        </Box>
      </FullScreenOverlay>
    );
  }
}

export default inject("TradeExecutionSummaryStore")(observer(FiltersOverlay));
