import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Button } from "@envestnet/envreact-component-library";
import { Typography } from "@material-ui/core";
import style from "./style.module.css";

class TradeExecutionFiltersDisplay extends Component {
  renderFilters() {
    if (
      this.props.TradeExecutionSummaryStore.SearchCriteriaList.searchCriteria
    ) {
      return this.props.TradeExecutionSummaryStore.SearchCriteriaList.searchCriteria.map(
        (searchCriteria, searchCriteriaIndex) => {
          let searchTypeAppliedValues = this.props.TradeExecutionSummaryStore.SearchCriteriaDropDownAppliedValues.find(
            (x) => x.searchType === searchCriteria.searchType
          );
          //Get Filter Label
          let filterLabels = [];
          if (
            searchTypeAppliedValues &&
            searchTypeAppliedValues.searchTypeValues
          ) {
            searchTypeAppliedValues.searchTypeValues.forEach(
              (filterValue, filterValueIndex) => {
                filterLabels.push(filterValue.label);
              }
            );
          } else {
            //Return
            filterLabels.push("-");
          }

          return (
            <div
              key={searchCriteriaIndex}
              className={`${style.searchCriteriaBox}`}
            >
              <Typography className={style.heading}>
                {searchCriteria.title}
              </Typography>
              <div className={`sidebarHeaderDetailText`}>
                {filterLabels.join(", ")}
              </div>
            </div>
          );
        }
      );
    } else {
      return null;
    }
  }

  renderEditClearAll() {
    return this.props.TradeExecutionSummaryStore.SearchCriteriaList
      .searchCriteria ? (
      <div className={`${style.editButtonText}`}>
        <Button
          name="Clear all"
          buttonStyle="link"
          className={`sidebarHeaderLinkText`}
          onClick={() =>
            this.props.TradeExecutionSummaryStore.ClearSearchCriteriaDropDownValues()
          }
        />
        <span className={style.vDivider}></span>
        <Button
          name="Edit"
          buttonStyle="link"
          className={`sidebarHeaderLinkText`}
          onClick={() =>
            this.props.TradeExecutionSummaryStore.OpenFiltersOverlay()
          }
        />
      </div>
    ) : null;
  }

  render() {
    return (
      <div>
        <div>{this.renderFilters()}</div>
        {this.renderEditClearAll()}
      </div>
    );
  }
}

export default inject("TradeExecutionSummaryStore")(
  observer(TradeExecutionFiltersDisplay)
);
