import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Typography } from "@material-ui/core";
import { SEARCH_CRITERIA_ENUM } from "~/src/constants/TradeExecutionSummary";
import {
  DropdownItem,
  SearchableDropdown,
} from "@envestnet/envreact-component-library";

import style from "./style.module.css";

class FiltersDropdownList extends Component {
  getFiltersDropdownValue(searchType) {
    let selectedSearchCriteriaDropdownValue = this.props.TradeExecutionSummaryStore.SearchCriteriaDropDownValues.find(
      (x) => x.searchType === searchType
    );

    return selectedSearchCriteriaDropdownValue &&
      selectedSearchCriteriaDropdownValue.searchTypeValues
      ? selectedSearchCriteriaDropdownValue.searchTypeValues
      : [];
  }

  isDropDownDisabled(option, searchCriteria) {
    let dropDownDownDisabled = false;

    //Find Selected Search Criteria Drop Down Values By SearchType
    let selectedDropDown = this.props.TradeExecutionSummaryStore.SearchCriteriaDropDownValues.find(
      (x) => x.searchType === searchCriteria.searchType
    );

    //If Selected DropDown Exist and Selected All Option: Make All Enabled and Rest Of the Options Disabled
    if (selectedDropDown) {
      dropDownDownDisabled =
        selectedDropDown.searchTypeValues.find(
          (x) => x.label.toLowerCase() === SEARCH_CRITERIA_ENUM.ALL
        ) && option.label.toLowerCase() !== SEARCH_CRITERIA_ENUM.ALL
          ? true
          : false;
    }

    return dropDownDownDisabled;
  }

  isOneOptionAvailable(searchCriteria) {
    return searchCriteria.searchTypeValues.length === 1;
  }

  renderFiltersDropdownOptions(option, searchCriteria) {
    return (
      <DropdownItem.Basic
        multiple
        value={option}
        disabled={this.isDropDownDisabled(option, searchCriteria)}
        key={option.value}
      >
        {option.label}
      </DropdownItem.Basic>
    );
  }

  getFiltersDropdownOptions(searchCriteria) {
    return searchCriteria.searchTypeValues &&
      searchCriteria.searchTypeValues.length > 0
      ? searchCriteria.searchTypeValues
          .sort((x, y) => {
            let a = x.label.toLowerCase(),
              b = y.label.toLowerCase();

            //Get (ALL) Label On Top
            if (a === SEARCH_CRITERIA_ENUM.ALL) {
              return -1;
            } else if (b === SEARCH_CRITERIA_ENUM.ALL) {
              return 1;
            } else {
              return 0;
            }
          })
          .map((searchTypeValue, searchTypeValueIndex) => {
            return searchTypeValue;
          })
      : [];
  }

  renderFiltersDropdownList() {
    return this.props.TradeExecutionSummaryStore.SearchCriteriaList
      .searchCriteria
      ? this.props.TradeExecutionSummaryStore.SearchCriteriaList.searchCriteria.map(
          (searchCriteria, searchCriteriaIndex) => {
            //Get Value
            let values = this.getFiltersDropdownValue(
              searchCriteria.searchType
            );

            return (
              <div
                className={`${style.filtersDropdown}`}
                key={searchCriteriaIndex}
              >
                <Typography className={`${style.filtersDropdownHeading}`}>
                  {searchCriteria.title}
                </Typography>
                <SearchableDropdown
                  multiple
                  onChange={(values) => {
                    this.props.TradeExecutionSummaryStore.OnChangeSearchCriteriaDropDownValues(
                      searchCriteria.searchType,
                      values
                    );
                  }}
                  searchFilter={(list, searchText) =>
                    list.filter((item) =>
                      item.label
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                  }
                  options={this.getFiltersDropdownOptions(searchCriteria)}
                  renderOption={(option) =>
                    this.renderFiltersDropdownOptions(option, searchCriteria)
                  }
                  value={values}
                  disabled={this.isOneOptionAvailable(searchCriteria)}
                  getValueName={(v) => v.label}
                />
                {}
              </div>
            );
          }
        )
      : null;
  }

  render() {
    return (
      <div className={`${style.filtersDropdownWrapper}`}>
        {this.renderFiltersDropdownList()}
      </div>
    );
  }
}

export default inject("TradeExecutionSummaryStore")(
  observer(FiltersDropdownList)
);
