import Alert from "react-s-alert";

//Private Methods
let getMsgHtml = (title, msg) => {
  return `<h1 class='notificationTitle'>${title}</h1>
  <div class='notificationMsg'>${msg}</div>`;
};

//Exported Methods
let ShowErrorMessage = (
  errorMsg,
  title = "",
  duration = "none",
  callback = null
) => {
  Alert.error(getMsgHtml(title, errorMsg), {
    position: `bottom-right`,
    effect: `jelly`,
    timeout: duration,
    html: true,
  });
};

let ShowSuccessMessage = (
  successMsg,
  title = "",
  duration = "none",
  callback = null
) => {
  Alert.success(getMsgHtml(title, successMsg), {
    position: `bottom-right`,
    effect: "jelly",
    timeout: duration,
    html: true,
  });
};

let ShowWarningMessage = (
  warningMsg,
  title = "",
  duration = "none",
  callback = null
) => {
  Alert.warning(getMsgHtml(title, warningMsg), {
    position: "bottom-right",
    effect: "jelly",
    timeout: duration,
    html: true,
  });
};

let ShowTitleWithProgressBar = (
  title = "In Progress",
  progressMsg = "",
  duration = "none",
  callback = null
) => {
  Alert.info(getMsgHtml(title, progressMsg), {
    position: "bottom-right",
    effect: "jelly",
    timeout: duration,
    html: true,
    customFields: {
      showProgressBar: true,
    },
  });
};

let CloseAllMessages = () => {
  Alert.closeAll();
};

let FormatError = (error) => {
  let formattedError = error;
  if (
    error.response &&
    error.response.data &&
    error.response.data.errors &&
    error.response.data.errors.length > 0
  ) {
    formattedError = `Error: ${error.response.data.errors[0].code} - ${error.response.data.errors[0].message}`;
  } else if (error.toString().includes("timeout")) {
    formattedError = `Error: Timeout exceeded 30s and failed after 3 retries. Please try reloading...`;
  }
  return formattedError;
};

export default {
  ShowErrorMessage,
  ShowSuccessMessage,
  ShowWarningMessage,
  ShowTitleWithProgressBar,
  CloseAllMessages,
  FormatError,
};
