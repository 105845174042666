import _ from 'lodash';
export const GetSessionStorage = name => {
  return sessionStorage.getItem(name);
};

export const SetSessionStorage = (name, value) => {
  sessionStorage.setItem(name, value);
};

export const DeleteSessionStorage = name => {
  sessionStorage.removeItem(name);
};

export const AddSessionStorage = (store, name, value) => {
  let sessionStore = GetSessionStorage(store);
  if (sessionStore) {
    sessionStore = JSON.parse(sessionStore);
    sessionStore[name] = value;
  } else {
    SetSessionStorage(store, JSON.stringify({}));
    sessionStore = GetSessionStorage(store);
    sessionStore = JSON.parse(sessionStore);
    sessionStore[name] = value;
  }
  SetSessionStorage(store, JSON.stringify(sessionStore));
};

export const FetchSessionStorage = (store, name) => {
  let sessionStore = GetSessionStorage(store);
  if (sessionStore) {
    sessionStore = JSON.parse(sessionStore);
    return sessionStore[name];
  } else {
    return null;
  }
};

export const RemoveSessionStorage = (store, name) => {
  let sessionStore = GetSessionStorage(store);
  if (sessionStore) {
    sessionStore = JSON.parse(sessionStore);
    sessionStore = _.omit(sessionStore, [name]);
    SetSessionStorage(store, JSON.stringify(sessionStore));
  }
};
