import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { FormatNumberOnlyComma } from "~/src/utils/formatter";
import style from "./style.module.css";

class TradeExecutionStatusesNonClickable extends Component {
  renderTradeExecutionStatuses() {
    let tradeExecutionStatusesList = this.props.TradeExecutionSummaryStore
      .GetTradeExecutionStatusesList;
    let tradeExecutionStatusesListNonClickableHtml = "";

    //Create Non Clickable HTML
    tradeExecutionStatusesListNonClickableHtml = tradeExecutionStatusesList.tradeExecutionStatusesNonClickable.map(
      (tradeExecutionNonClickable, tradeExecutionNonClickableIndex) => {
        return (
          <div key={tradeExecutionNonClickableIndex}>
            <div
              className={`${style.tradeExecutionStatusesListRow} ${style.nonClickableStatusesName}`}
            >
              <div>{tradeExecutionNonClickable.tradeExecutionStatusName}</div>
              <div className={`${style.tradeExecutionStatusesListCount}`}>
                {FormatNumberOnlyComma(
                  tradeExecutionNonClickable.tradeExecutionStatusCount
                )}
              </div>
            </div>
          </div>
        );
      }
    );

    return (
      <div>
        <div className={`${style.nonClickableWrapper}`}>
          {tradeExecutionStatusesListNonClickableHtml}
        </div>
      </div>
    );
  }

  render() {
    return <div>{this.renderTradeExecutionStatuses()}</div>;
  }
}

export default inject("TradeExecutionSummaryStore")(
  observer(TradeExecutionStatusesNonClickable)
);
