export default {
  TESSessionStorage: "TESSessionStorage",
  IsAppOpenAsPopup: "IsAppOpenAsPopup",
  SearchCriteria: "SearchCriteria",
  //Init Session Variable
  Token: "Token",
  UserHandle: "UserHandle",
  OpenEnvUrl: "OpenEnvUrl",
  MicroServiceUrl: "MicroServiceUrl",
};
