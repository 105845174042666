import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";
import "./style.css";

class NotificationsTemplate extends React.Component {
  getProgressBar() {
    let progressBar = null;
    if (this.props.customFields.showProgressBar) {
      progressBar = (
        <div className="progressBar">
          <LinearProgress />
        </div>
      );
    }
    return progressBar;
  }

  render() {
    let customClass = this.props.customFields.showProgressBar
      ? "showProgressBarWithSpinner"
      : "";
    return (
      <div
        className={`${this.props.classNames} ${customClass}`}
        id={this.props.id}
        style={this.props.styles}
      >
        <div className="s-alert-box-inner">
          {this.props.message}
          {this.getProgressBar()}
        </div>

        <span className="s-alert-close" onClick={this.props.handleClose}></span>
      </div>
    );
  }
}

export default NotificationsTemplate;
