import React from "react";
import { Route, Redirect } from "react-router-dom";
import { observer, inject } from "mobx-react";
import routes from "~/src/routes";

const PublicRoute = inject("AuthenticationStore")(
  observer(({ component: Component, restricted, ...rest }) => {
    return (
      // restricted = false meaning public route
      // restricted = true meaning restricted route
      <Route
        {...rest}
        render={(props) =>
          rest.AuthenticationStore.IsLoggedIn && restricted ? (
            <Redirect to={routes.tes} />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  })
);

export default PublicRoute;
