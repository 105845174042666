import React from "react";
import { CircularProgress } from "@envestnet/envreact-component-library";
import style from "./style.module.css";

export default function CircularIndeterminate(props) {
  return props.IsLoading ? (
    <div className={style.overlay}>
      <div className={style.overlay_center}>
        <CircularProgress color="primary" size={36} variant="indeterminate" />
      </div>
    </div>
  ) : null;
}
