import React from "react";
import { observer, inject } from "mobx-react";
import { Grid } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { Base64 } from "js-base64";
import backgroundImg from "~/src/static/img/bg.jpg";
import autobind from "react-autobind";
import routes from "~/src/routes";
import { QUERY_STRING_ENUM } from "~/src/constants/TradeExecutionSummary";
import Loader from "~/src/components/Common/Loader";
import LoginForm from "~/src/components/Authentication/LoginForm";
import notifications from "~/src/utils/notifications";
import Variables from "~/src/variables/";
import style from "./style.module.css";

class Login extends React.Component {
  constructor() {
    super();
    autobind(this);
  }

  componentDidMount() {
    //Close All Messages On Mount
    notifications.CloseAllMessages();

    //Check TES Session Query String Param
    this.checkWorksheetSessionQueryStringParam();
  }

  //Check TES Session On Load
  checkWorksheetSessionQueryStringParam() {
    let param = new URLSearchParams(window.location.search);
    let tesSessionFromQS = param.get(`${QUERY_STRING_ENUM.TES_SESSION}`);

    //If TES Session Is Not Found On Reload Page Then Check In Session Storage
    if (!tesSessionFromQS) {
      if (sessionStorage[Variables.TESSessionStorage]) {
        tesSessionFromQS = JSON.parse(
          sessionStorage[Variables.TESSessionStorage]
        );
      }
    }

    if (tesSessionFromQS) {
      try {
        Base64.extendString();
        let decodedWorksheetSessionJSON = JSON.parse(
          tesSessionFromQS.fromBase64()
        );
        this.props.AuthenticationStore.RootStore.TradeExecutionSummaryStore.SetWorksheetPopupSession(
          decodedWorksheetSessionJSON
        );
      } catch (error) {
        notifications.CloseAllMessages();
        notifications.ShowErrorMessage(notifications.FormatError(error));
      }
    } else {
      if (this.props.AuthenticationStore.RootStore.IsProdEnv) {
        setTimeout(() => {
          notifications.ShowErrorMessage(
            "TES Session Expired, Please Logout and Try Again..."
          );
        }, 100);
      }
    }
  }

  renderLoginForm() {
    return <LoginForm />;
  }

  renderLoginPage() {
    let loginPage = null;

    if (!this.props.AuthenticationStore.RootStore.IsProdEnv) {
      loginPage = (
        <Grid container spacing={0} className="login">
          <Grid
            item
            xs={4}
            className={style.login_left_side_bar}
            style={{ backgroundImage: "url(" + backgroundImg + ")" }}
          />
          <Grid item xs={8}>
            <div className={style.login_form_box}>{this.renderLoginForm()}</div>
          </Grid>
        </Grid>
      );
    }

    return loginPage;
  }

  render() {
    if (!this.props.AuthenticationStore.RootStore.IsLoading) {
      if (this.props.AuthenticationStore.IsLoggedIn) {
        return <Redirect to={routes.tes} />;
      } else {
        return this.renderLoginPage();
      }
    } else {
      return (
        <Loader
          IsLoading={this.props.AuthenticationStore.RootStore.IsLoading}
        />
      );
    }
  }
}

export default inject("AuthenticationStore")(observer(Login));
