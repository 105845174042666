import * as mobx from "mobx";
import excel from "~/src/utils/excel";
import { AddSessionStorage, FetchSessionStorage } from "~/src/utils/storage";
import Variables from "~/src/variables/";
import { Base64 } from "js-base64";
import SERVICES from "~/src/services";
import {
  GetSearchCriteriaListAPI,
  GetTradeExecStatusCountAPI,
  GetTradeExecStatusDetailsAPI,
  GetTESSavedLayoutAPI,
  PostSaveAsLayoutAPI,
  GetBrandConfigurationAPI,
} from "~/src/services/TradeExecutionSummaryService";
import { CallTokenAPI } from "~/src/services/AuthenticationService";
import notifications from "~/src/utils/notifications";
import {
  QUERY_STRING_ENUM,
  TRADE_EXECUTION_CLICKABLE_STATUSES_ENUM,
  TRADE_EXECUTION_CLICKABLE_STATUSES_OBJECT_ENUM,
  TRADE_STATUS_REFRESH_TIME_ENUM,
  SEARCH_CRITERIA_ENUM,
  COLUMNS_ENUM,
  FIELD_ENUM,
  EXCEL_EXPORT_ENUM,
} from "~/src/constants/TradeExecutionSummary";
import { FormatAmount, FormatNumber } from "~/src/utils/formatter";
import { MESSAGES } from "./messages";
import TradeExecutionSummaryStoreActions from "./TradeExecutionSummaryStoreActions";
const { observable, computed, flow } = mobx;
//Temp Bind to Window Object for Testing
window.mobx = mobx;
class TradeExecutionSummaryStore extends TradeExecutionSummaryStoreActions {
  constructor(RootStore) {
    super();
    this.RootStore = RootStore;
  }

  @observable AgGridPageSize = 100;
  @observable AgGridCurrentPage = 1;
  @observable IsPaginationReady = false;
  //Trade Execution Statuses List In Side Bar
  @observable TradeExecutionStatusesList = {};
  @observable TradeExecutionSelectedStatuses = [];
  //Trade Status Refresh Timer
  @observable TradeExecutionStatusTimer = TRADE_STATUS_REFRESH_TIME_ENUM;
  @observable IsTradeExecutionStatusTimerPause = true;
  //Trade Execution Filters For Search
  @observable SearchCriteriaList = {};
  @observable SearchCriteriaDropDownValues = [];
  @observable SearchCriteriaDropDownAppliedValues = [];
  @observable IsSearchCriteriaSaveToLayout = false;
  @observable SearchCriteriaSavedLayoutFromAPI = null;
  //Trade Execution Filters Overlay Open
  @observable IsFilterOverlayOpen = false;
  //To Pass Values Common Values For Editable Actions
  @observable IsGridButtonsDisabled = false;
  //To Toggle Grid Loading On Load
  @observable GridLoading = false;
  //Brand Configuration
  @observable BrandConfiguration = null;

  //SERVICES //API Calls
  GetInit = flow(function* () {
    try {
      if (this.RootStore.AuthenticationStore.IsLoggedIn) {
        this.RootStore.LoadingOn();

        //Init Brand Configuration
        let brandConfigurationResponse = yield GetBrandConfigurationAPI(
          this.Token,
          this.UserHandle
        );
        this.SetBrandConfiguration(brandConfigurationResponse);

        //Init Search Criteria List
        let searchCriteriaListResponse = yield GetSearchCriteriaListAPI(
          this.Token
        );
        //Set Search Criteria List
        this.SetSearchCriteriaList(searchCriteriaListResponse);
        //Get Saved Layout
        let tesSavedLayoutResponse = yield GetTESSavedLayoutAPI(this.Token);
        this.SetSearchCriteriaSavedLayoutFromAPI(tesSavedLayoutResponse);
        //Set Search Criteria In Session Storage
        this.SetSearchCriteriaDropDownValuesFromSessionStorage();
        this.RootStore.LoadingOff();
      } else {
        notifications.ShowErrorMessage("Not Authorized");
        this.RootStore.LoadingOff();
      }
    } catch (error) {
      this.RootStore.LoadingOff();
      notifications.CloseAllMessages();
      notifications.ShowErrorMessage(notifications.FormatError(error));
    }
  });
  GetTradeExecutionStatuses = flow(function* () {
    try {
      notifications.CloseAllMessages();
      this.SetIsTradeExecutionStatusTimerPause(true);
      //Init Trade Execution Status List
      let tradeExecStatusCountResponse = yield GetTradeExecStatusCountAPI(
        this.Token,
        this.SearchCriteriaList.marketDate,
        this.SearchCriteriaDropDownAppliedValues
      );
      this.TradeExecutionStatusesList = tradeExecStatusCountResponse.data;
      setTimeout(() => {
        //If Not Active Status Found Select First One Else Make Server Side Call.
        if (Object.keys(this.GetTradeExecutionActiveStatus).length === 0) {
          //Set First Trade Execution Status
          this.SetFirstTradeExecutionStatus();
        } else {
          //Set Selected Status If Count Changed
          this.TradeExecutionStatusesList.statusCount.tradeExecutionStatuses.forEach(
            (tradeExecutionStatusVal) => {
              if (
                tradeExecutionStatusVal.tradeExecutionStatus ===
                this.GetTradeExecutionActiveStatus.tradeExecutionStatus
              ) {
                this.SetTradeExecutionSelectedStatuses(tradeExecutionStatusVal);
              }
            }
          );
        }
      }, 100);
      //Show Notification If Status is not available
      if (!this.IsTradeStatusAvailable) {
        notifications.CloseAllMessages();
        notifications.ShowErrorMessage("Trade execution statuses not found..");
      }
    } catch (error) {
      this.RootStore.LoadingOff();
      notifications.CloseAllMessages();
      notifications.ShowErrorMessage(notifications.FormatError(error));
    }
  });
  ExportAgGrid = flow(function* () {
    try {
      notifications.CloseAllMessages();
      this.RootStore.LoadingOn();
      //Init Trade Execution Status List
      let token = this.Token;
      let size = this.AgGridPageSize;
      let marketDate = this.SearchCriteriaList.marketDate;
      let searchCriteria = this.SearchCriteriaDropDownAppliedValues;
      let executionStatus = this.GetTradeExecutionActiveStatus
        .tradeExecutionStatus;
      //Get Execution Details with page -1
      let tradeExecStatusDetailsResponse = yield GetTradeExecStatusDetailsAPI(
        token,
        -1,
        size,
        marketDate,
        searchCriteria,
        executionStatus
      );
      this.RootStore.LoadingOff();
      if (
        tradeExecStatusDetailsResponse.data &&
        tradeExecStatusDetailsResponse.data.orderDetails &&
        tradeExecStatusDetailsResponse.data.orderDetails
          .tradeOrderExecutionDetails.length > 0
      ) {
        let data =
          tradeExecStatusDetailsResponse.data.orderDetails
            .tradeOrderExecutionDetails;
        //Create Project Name
        let projectName = EXCEL_EXPORT_ENUM.PROJECT;
        const today = new Date();

        let fileName = `${projectName}-${
          today.getMonth() + 1
        }-${today.getDate()}-${today.getFullYear()}`;
        var options = {
          fileName: fileName,
        };
        //Generate Columns as per Excel Export
        var columns = [
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.ACCOUNT_NUMBER,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.TICKER,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.BUY_SELL,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.SHARES,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.AMOUNT,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.EXPIRATION_DATE,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.PRICE_QUALIFIER,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.ADVISOR,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.SUBMITTED_BY,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.STATUS,
          },
          {
            style: {
              fontWeight: {
                bold: true,
              },
            },
            text: COLUMNS_ENUM.EXCEPTION_DETAILS,
          },
        ];
        //Generate Rows as per Excel Export
        var rows = data.map(function (item) {
          return [
            { text: item[FIELD_ENUM.ACCOUNT_NUMBER] },
            { text: item[FIELD_ENUM.TICKER] },
            { text: item[FIELD_ENUM.BUY_SELL] },
            { text: item[FIELD_ENUM.SHARES] },
            { text: item[FIELD_ENUM.AMOUNT] },
            { text: item[FIELD_ENUM.EXPIRATION_DATE] },
            { text: item[FIELD_ENUM.PRICE_QUALIFIER] },
            { text: item[FIELD_ENUM.ADVISOR] },
            { text: item[FIELD_ENUM.SUBMITTED_BY] },
            { text: item[FIELD_ENUM.STATUS] },
            { text: item[FIELD_ENUM.EXCEPTION_DETAILS] },
          ];
        });
        //Create Table Data
        var tableData = [
          {
            sheetName: fileName,
            data: [columns, ...rows],
          },
        ];
        //Export To Excel
        excel.export(tableData, options);
      } else {
        notifications.ShowErrorMessage("No Data For Export...");
      }
    } catch (error) {
      this.RootStore.LoadingOff();
      notifications.CloseAllMessages();
      notifications.ShowErrorMessage(notifications.FormatError(error));
    }
  });

  //Saved Layout API:
  PostSaveLayout = flow(function* () {
    try {
      if (this.RootStore.AuthenticationStore.IsLoggedIn) {
        this.RootStore.LoadingOn();
        //POST Saved Layout API
        let saveLayoutResponse = yield PostSaveAsLayoutAPI(this.Token, {
          ...this.GetNewSavedLayoutObject(),
        });
        //Show Success And Error Messages
        if (
          saveLayoutResponse.statusText === "OK" &&
          saveLayoutResponse.status === 200
        ) {
          notifications.ShowSuccessMessage(
            MESSAGES.SAVED_LAYOUT_SUCCESS,
            "Success",
            5000
          );
          this.SetSearchCriteriaSavedLayoutFromAPI(saveLayoutResponse);
        } else {
          notifications.ShowErrorMessage(
            MESSAGES.SAVED_LAYOUT_ERROR,
            "Error",
            5000
          );
        }
        this.RootStore.LoadingOff();
      } else {
        notifications.ShowErrorMessage(MESSAGES.NOT_AUTHORIZED);
        this.RootStore.LoadingOff();
      }
    } catch (error) {
      notifications.CloseAllMessages();
      notifications.ShowErrorMessage(notifications.FormatError(error));
      this.RootStore.LoadingOff();
    }
  });

  //* COMPUTED
  //Session Storage Items
  @computed get Token() {
    return this.RootStore.AuthenticationStore.GetToken();
  }

  @computed get UserHandle() {
    return this.RootStore.AuthenticationStore.GetUserHandle();
  }

  @computed get IsAppOpenAsPopup() {
    return FetchSessionStorage(
      Variables.TESSessionStorage,
      Variables.IsAppOpenAsPopup
    );
  }

  @computed get GetAgGridColDef() {
    let activeTradeExecutionStatuses = this.GetTradeExecutionActiveStatus;

    let colDefs = [];
    if (
      activeTradeExecutionStatuses.tradeExecutionStatusName ===
        TRADE_EXECUTION_CLICKABLE_STATUSES_OBJECT_ENUM.REJECTED ||
      activeTradeExecutionStatuses.tradeExecutionStatusName ===
        TRADE_EXECUTION_CLICKABLE_STATUSES_OBJECT_ENUM.EXECUTION_PENDING
    ) {
      colDefs = [
        {
          headerName: COLUMNS_ENUM.ACCOUNT_NUMBER,
          headerTooltip: COLUMNS_ENUM.ACCOUNT_NUMBER,
          field: FIELD_ENUM.ACCOUNT_NUMBER,
          colId: FIELD_ENUM.ACCOUNT_NUMBER,
          width: 150,
          cellStyle: (params) => {
            return { fontWeight: 700 };
          },
        },
        {
          headerName: COLUMNS_ENUM.TICKER,
          headerTooltip: COLUMNS_ENUM.TICKER,
          field: FIELD_ENUM.TICKER,
          width: 150,
          colId: FIELD_ENUM.TICKER,
        },
        {
          headerName: COLUMNS_ENUM.BUY_SELL,
          headerTooltip: COLUMNS_ENUM.BUY_SELL,
          field: FIELD_ENUM.BUY_SELL,
          colId: FIELD_ENUM.BUY_SELL,
          width: 150,
        },
        {
          headerName: COLUMNS_ENUM.SHARES,
          headerTooltip: COLUMNS_ENUM.SHARES,
          field: FIELD_ENUM.SHARES,
          colId: FIELD_ENUM.SHARES,
          width: 150,
          headerClass: "agGridHeaderRightAlign",
          cellStyle: (params) => {
            return { textAlign: "right" };
          },
          valueFormatter: (params) => {
            return params.value ? FormatNumber(params.value, 2) : "--";
          },
        },
        {
          headerName: COLUMNS_ENUM.AMOUNT,
          headerTooltip: COLUMNS_ENUM.AMOUNT,
          field: FIELD_ENUM.AMOUNT,
          colId: FIELD_ENUM.AMOUNT,
          width: 150,
          headerClass: "agGridHeaderRightAlign",
          cellStyle: (params) => {
            return { textAlign: "right" };
          },
          valueFormatter: (params) => {
            return params.value ? FormatAmount(params.value) : "--";
          },
        },
        {
          headerName: COLUMNS_ENUM.EXPIRATION_DATE,
          headerTooltip: COLUMNS_ENUM.EXPIRATION_DATE,
          field: FIELD_ENUM.EXPIRATION_DATE,
          colId: FIELD_ENUM.EXPIRATION_DATE,
          width: 150,
        },
        {
          headerName: COLUMNS_ENUM.PRICE_QUALIFIER,
          headerTooltip: COLUMNS_ENUM.PRICE_QUALIFIER,
          field: FIELD_ENUM.PRICE_QUALIFIER,
          colId: FIELD_ENUM.PRICE_QUALIFIER,
          width: 150,
        },
        {
          headerName: COLUMNS_ENUM.ADVISOR,
          headerTooltip: COLUMNS_ENUM.ADVISOR,
          field: FIELD_ENUM.ADVISOR,
          colId: FIELD_ENUM.ADVISOR,
          width: 150,
        },
        {
          headerName: COLUMNS_ENUM.SUBMITTED_BY,
          headerTooltip: COLUMNS_ENUM.SUBMITTED_BY,
          field: FIELD_ENUM.SUBMITTED_BY,
          colId: FIELD_ENUM.SUBMITTED_BY,
          width: 150,
        },
        {
          headerName: COLUMNS_ENUM.STATUS,
          headerTooltip: COLUMNS_ENUM.STATUS,
          field: FIELD_ENUM.STATUS,
          colId: FIELD_ENUM.STATUS,
          width: 150,
        },
        {
          headerName: COLUMNS_ENUM.EXCEPTION_DETAILS,
          headerTooltip: COLUMNS_ENUM.EXCEPTION_DETAILS,
          field: FIELD_ENUM.EXCEPTION_DETAILS,
          colId: FIELD_ENUM.EXCEPTION_DETAILS,
          width: 150,
        },
      ];
    }
    return colDefs;
  }

  @computed get GetAgGridRowData() {
    return [];
  }

  @computed get ServerSideDataSource() {
    let token = this.Token;
    let totalRecords = this.GetTotalRecords();
    let size = this.AgGridPageSize;
    let marketDate = this.SearchCriteriaList.marketDate;
    let searchCriteria = this.SearchCriteriaDropDownAppliedValues;
    let executionStatus = this.GetTradeExecutionActiveStatus
      .tradeExecutionStatus;
    return {
      getRows: (params) => {
        this.SetIsGridButtonsDisabled(true);
        let page = params.request.endRow / size - 1;
        GetTradeExecStatusDetailsAPI(
          token,
          page,
          size,
          marketDate,
          searchCriteria,
          executionStatus
        )
          .then((rowData) => {
            this.TradeExecStatusSuccess(params, rowData, totalRecords);
          })
          .catch((error) => {
            notifications.CloseAllMessages();
            notifications.ShowErrorMessage(notifications.FormatError(error));
          });
      },
    };
  }

  @computed get GetOverlayLoadingTemplate() {
    return `<div class='agGridCustomOverlay'>
        <div class='boldText'>There are no trade exceptions within your set filter</div>
        <br />
        <div>Click Edit to change your filter or click Close to dismiss this message.</div>
        <div id='agGridCustomOverlayButtonWrapper'></div>
      </div>`;
  }

  @computed get GetOverlayNoRowsTemplate() {
    return `<span style='font-size:14px;'>There are currently no trade exceptions.</span>`;
  }

  @computed get IsEachSearchCriteriaAll() {
    let isEachSearchCriteriaAll = true;
    let searchCriteriaDropDownAppliedValues = this
      .SearchCriteriaDropDownAppliedValues;
    //If Criteria List Length is not matched with Applied List Then Make It False
    if (
      this.SearchCriteriaList.searchCriteria.length !==
      searchCriteriaDropDownAppliedValues.length
    ) {
      isEachSearchCriteriaAll = false;
    } else if (
      //If Criteria List Length is  matched with Applied List Then Check If Applied List Contains Each "All" Value
      this.SearchCriteriaList.searchCriteria.length ===
      searchCriteriaDropDownAppliedValues.length
    ) {
      //If Applied Values has 1 Non "All" Value
      searchCriteriaDropDownAppliedValues.forEach((x) => {
        if (
          !x.searchTypeValues.find(
            (y) => y.label.toLowerCase() === SEARCH_CRITERIA_ENUM.ALL
          )
        ) {
          isEachSearchCriteriaAll = false;
        }
      });
    }
    return isEachSearchCriteriaAll;
  }

  //Trade Execution State Computed
  @computed get IsTradeStatusAvailable() {
    let isTradeStatusAvailable = false;
    if (Object.keys(this.TradeExecutionStatusesList).length > 0) {
      isTradeStatusAvailable =
        this.TradeExecutionStatusesList.statusCount &&
        this.TradeExecutionStatusesList.statusCount.tradeExecutionStatuses
          .length > 0;
    }

    return isTradeStatusAvailable;
  }

  @computed get GetTradeExecutionStatusesList() {
    //Get Clickable Statuses
    let tradeExecutionStatusesClickable =
      this.TradeExecutionStatusesList.statusCount &&
      this.TradeExecutionStatusesList.statusCount.tradeExecutionStatuses
        ? this.TradeExecutionStatusesList.statusCount.tradeExecutionStatuses.filter(
            (x) =>
              TRADE_EXECUTION_CLICKABLE_STATUSES_ENUM.includes(
                x.tradeExecutionStatusName
              )
          )
        : [];
    //Get Non Clickable Statuses
    let tradeExecutionStatusesNonClickable =
      this.TradeExecutionStatusesList.statusCount &&
      this.TradeExecutionStatusesList.statusCount.tradeExecutionStatuses
        ? this.TradeExecutionStatusesList.statusCount.tradeExecutionStatuses.filter(
            (x) =>
              !TRADE_EXECUTION_CLICKABLE_STATUSES_ENUM.includes(
                x.tradeExecutionStatusName
              )
          )
        : [];
    return {
      tradeExecutionStatusesClickable,
      tradeExecutionStatusesNonClickable,
    };
  }

  @computed get GetTradeExecutionActiveStatus() {
    let activeStatus = this.TradeExecutionSelectedStatuses.find(
      (x) => x.active
    );
    return activeStatus ? activeStatus : {};
  }

  //LAUNCH
  SetWorksheetPopupSession = flow(function* (decodedWorksheetSessionJSON) {
    try {
      //Set IsAppOpenAsPopup
      AddSessionStorage(
        Variables.TESSessionStorage,
        Variables.IsAppOpenAsPopup,
        true
      );
      //Set OpenEnvURL & MicroService Url from Session
      this.SetOpenEnvUrl(decodedWorksheetSessionJSON.openEnvUrl);
      this.SetMicroServiceUrl(decodedWorksheetSessionJSON.microServiceUrl);
      //If Not Local Env Then Get The Long Token using Opaque Token
      if (this.RootStore.IsProdEnv) {
        this.RootStore.LoadingOn();
        let callTokenResponse = yield CallTokenAPI(
          decodedWorksheetSessionJSON.token
        );
        if (callTokenResponse.status === 200) {
          let longToken = callTokenResponse.headers.token;
          //Settoken in Session Storage
          this.RootStore.AuthenticationStore.SetToken(longToken);
          //SetUserHandle in Session Storage
          this.RootStore.AuthenticationStore.SetUserHandle(
            callTokenResponse.data.userHandle
          );
        }
        this.RootStore.LoadingOff();
      } else {
        AddSessionStorage(
          Variables.TESSessionStorage,
          Variables.Token,
          decodedWorksheetSessionJSON.token
        );
      }
      this.RootStore.AuthenticationStore.UpdatedLoggedInStatus();
    } catch (error) {
      notifications.CloseAllMessages();
      notifications.ShowErrorMessage(notifications.FormatError(error));
    }
  });

  LaunchWorksheetPopupByGeneratedQueryString = flow(function* (
    token,
    userHandle
  ) {
    //Set Query String
    if (window) {
      yield;
      //Create Object for Base64 String
      let tesSessionObject = {
        clientHandle: userHandle,
        token: token,
        openEnvUrl: SERVICES.API_ROOT().openEnvUrl, // In Local Dev Using Env Dev Url from Services.js
        microServiceUrl: SERVICES.API_ROOT().microServiceUrl, // In Local Dev Using Env Dev Url from Services.js
      };
      //Create Base64 String For Popup
      Base64.extendString();
      let encryptedWorksheetSessionStr = JSON.stringify(
        tesSessionObject
      ).toBase64URI();
      let encrypterWorksheetSessionQueryString = `?${QUERY_STRING_ENUM.TES_SESSION}=${encryptedWorksheetSessionStr}`;
      //Create WST URL with Query String
      var popupURL = `${window.location.protocol}//${
        window.location.host + window.location.pathname
      }${encrypterWorksheetSessionQueryString}`;
      window.open(
        popupURL,
        "worksheet",
        "fullscreen=yes,menubar=no,location=no,resizable=yes,scrollbars=yes,status=no"
      );
    }
  });
}

export default TradeExecutionSummaryStore;
