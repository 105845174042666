import React from "react";
import { observer, inject } from "mobx-react";
import { Typography } from "@material-ui/core";
import style from "./style.module.css";

class Logo extends React.Component {
  getLogoFromBrandConfigutation() {
    let brandConfigLogo = null;
    let brandConfiguration = this.props.TradeExecutionSummaryStore
      .BrandConfiguration;
    if (brandConfiguration) {
      if (
        brandConfiguration.advisorBrands &&
        brandConfiguration.advisorBrands.length > 0
      ) {
        if (
          brandConfiguration.advisorBrands[0].brandLogos &&
          brandConfiguration.advisorBrands[0].brandLogos.length > 0
        ) {
          let base64Logo =
            brandConfiguration.advisorBrands[0].brandLogos[0].brandLogo;
          brandConfigLogo = (
            <span>
              <img src={base64Logo} alt="logo" className={style.logoImg} />
              <span className={`${style.logoDivider}`}></span>
            </span>
          );
        }
      }
    }
    return brandConfigLogo;
  }

  render() {
    return (
      <div className={style.logo}>
        {this.getLogoFromBrandConfigutation()}
        <Typography className={`${style.logoText}`}>
          Trade status monitor
        </Typography>
      </div>
    );
  }
}

export default inject("TradeExecutionSummaryStore")(observer(Logo));
