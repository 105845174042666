import { action } from "mobx";
import {
  AddSessionStorage,
  FetchSessionStorage,
} from "~/src/utils/storage";
import notifications from "~/src/utils/notifications";
import Variables from "~/src/variables/";

class AuthenticationStoreActions {
  @action LogOutAction = () => {
    try {
      //On Logout Close The Window If Open As Popup
      if (this.RootStore.TradeExecutionSummaryStore.IsAppOpenAsPopup) {
        this.SetIsLogoutModalOpen(true);
      } else {
        this.LogoutAndClearSession();
      }
    } catch (error) {
      notifications.CloseAllMessages();
      notifications.ShowErrorMessage(notifications.FormatError(error));
    }
  };

  @action GetToken = () => {
    return FetchSessionStorage(Variables.TESSessionStorage, Variables.Token);
  };

  @action SetToken = (token) => {
    //Set Token in Session Storage
    AddSessionStorage(Variables.TESSessionStorage, Variables.Token, token);
  };

  @action GetUserHandle = () => {
    return FetchSessionStorage(
      Variables.TESSessionStorage,
      Variables.UserHandle
    );
  };

  @action SetUserHandle = (userHandle) => {
    //Set UserHandle in Session Storage
    AddSessionStorage(
      Variables.TESSessionStorage,
      Variables.UserHandle,
      userHandle
    );
  };

  @action SetIsLogoutModalOpen(isLogoutModalOpen) {
    this.IsLogoutModalOpen = isLogoutModalOpen;
  }
}

export default AuthenticationStoreActions;
