import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Typography } from "@material-ui/core";
import { Button } from "@envestnet/envreact-component-library";
import TradeExecutionStatusesClickable from "~/src/components/TradeExecutionSummary/TradeExecutionStatusesClickable";
import TradeExecutionStatusesNonClickable from "~/src/components/TradeExecutionSummary/TradeExecutionStatusesNonClickable";
import TradeExecutionFiltersDisplay from "~/src/components/TradeExecutionSummary/TradeExecutionFiltersDisplay";
import style from "./style.module.css";

class SideBar extends Component {
  renderNextUpdateText() {
    return this.props.TradeExecutionSummaryStore
      .IsTradeExecutionStatusTimerPause ? (
      <Typography className={`sidebarHeaderDetailText`}>Updating...</Typography>
    ) : (
      <Typography className={`sidebarHeaderDetailText`}>
        Next update in{" "}
        {this.props.TradeExecutionSummaryStore.TradeExecutionStatusTimer} sec.
      </Typography>
    );
  }

  renderRefreshButton() {
    return this.props.TradeExecutionSummaryStore.SearchCriteriaList
      .searchCriteria ? (
      <Button
        name="Refresh"
        buttonStyle="link"
        className={`sidebarHeaderLinkText`}
        onClick={() =>
          this.props.TradeExecutionSummaryStore.RefreshTradeExecutionStatus()
        }
      >
        Refresh
      </Button>
    ) : null;
  }

  render() {
    return (
      <div>
        <TradeExecutionStatusesClickable />

        <div className={`${style.sidebarBoxScroll}`}>
          <TradeExecutionStatusesNonClickable />

          <div className={`${style.sidebarBoxLeftRight}`}>
            {this.renderNextUpdateText()}
            {this.renderRefreshButton()}
          </div>

          <div className={`${style.divider}`}></div>

          <TradeExecutionFiltersDisplay />

          <div className={`${style.divider}`}></div>
        </div>
      </div>
    );
  }
}

export default inject("TradeExecutionSummaryStore")(observer(SideBar));
