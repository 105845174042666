import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import $ from "jquery";
import autobind from "react-autobind";
import { Input, Checkbox } from "@material-ui/core";
import { Button } from "@envestnet/envreact-component-library";
import style from "./style.module.css";
import Services from "~/src/services";
@inject("AuthenticationStore")
@observer
class LoginForm extends Component {
  constructor(props) {
    super(props);

    autobind(this);
    this.state = {
      formName: "LoginForm"
    };
  }

  componentDidMount() {
    require("jquery-validation");
    this.validateLoginForm();
  }

  handleLoginFormSubmit() {
    var isLoginFormValid = $(`#${this.state.formName}`).valid();

    if (isLoginFormValid) {
      var username = $(`#${this.state.formName}`)
        .find("input[name=username]")
        .val();
      var password = $(`#${this.state.formName}`)
        .find("input[name=password]")
        .val();
      var channel = $(`#${this.state.formName}`)
        .find("input[name=channel]")
        .val();
        var use_link_login = $(`#${this.state.formName}`)
        .find("input[name=use_link_login]")
        .is(":checked");

      //Call Login Action
      this.props.AuthenticationStore.LoginAction(
        username,
        password,
        channel,
        use_link_login
      );
    }
  }

  validateLoginForm() {
    $(`#${this.state.formName}`).validate({
      rules: {
        username: {
          required: true,
          minlength: 4
        },
        password: {
          required: true,
          minlength: 6
        },
        channel: {
          required: true
        }
      },
      messages: {
        username: {
          required: "Username required",
          minlength: $.validator.format("At least {0} characters required!")
        },
        password: {
          required: "Password required",
          minlength: $.validator.format("At least {0} characters required!")
        },
        channel: {
          required: "Channel required"
        }
      },
      submitHandler: form => {
        return false;
      }
    });
  }

  renderPage() {
    return (
      <div className={style.login_form}>
        <div className={style.login_top_label}>Log in to Trade Execution Summary</div>
        <form id={this.state.formName} className="login-form">
          <div className={style.login_input_box}>
            <Input
              id="username"
              name="username"
              placeholder="Username"
              type="text"
              defaultValue={Services.USERNAME}
              fullWidth
            />
          </div>
          <div className={style.login_input_box}>
            <Input
              id="password"
              name="password"
              placeholder="Password"
              type="password"
              defaultValue={Services.PASSWORD}
              fullWidth
            />
          </div>
          <div className={style.login_input_box}>
            <Input
              id="channel"
              name="channel"
              placeholder="Channel"
              type="text"
              defaultValue={Services.CHANNEL}
              fullWidth
            />
          </div>
          <div className={style.checkBoxWrapper}>
            <Checkbox
              value={true}
              inputProps={{
                id: "useLinkLogin",
                name: "use_link_login"
              }}
            />
            <div className={style.checkbox}>
              <label>Use Login Link</label>
            </div>
          </div>
          <Button name="Log in" onClick={this.handleLoginFormSubmit} fullWidth />
        </form>
      </div>
    );
  }

  render() {
    return this.renderPage();
  }
}

export default LoginForm;
