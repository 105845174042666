import { observable, action, computed } from "mobx";

import AuthenticationStore from "./AuthenticationStore";
import TradeExecutionSummaryStore from "./TradeExecutionSummaryStore";

export default class RootStore {
  constructor() {
    this.AuthenticationStore = new AuthenticationStore(this);
    this.TradeExecutionSummaryStore = new TradeExecutionSummaryStore(this);
  }

  @observable IsLoading = false;

  @action LoadingOn() {
    this.IsLoading = true;
  };

  @action LoadingOff() {
    this.IsLoading = false;
  };

  @computed get IsProdEnv() {
    return process.env.NODE_ENV === "production";
  }
}
