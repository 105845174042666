import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Typography } from "@material-ui/core";
import { FormatNumberOnlyComma } from "~/src/utils/formatter";
import style from "./style.module.css";

class TradeExecutionStatusesClickable extends Component {
  renderTradeExecutionStatuses() {
    let tradeExecutionStatusesList = this.props.TradeExecutionSummaryStore
      .GetTradeExecutionStatusesList;
    let tradeExecutionStatusesListClickableHtml = "";

    //Create Clickable HTML
    tradeExecutionStatusesListClickableHtml = tradeExecutionStatusesList.tradeExecutionStatusesClickable.map(
      (tradeExecutionClickable, tradeExecutionClickableIndex) => {
        //Get Active Link Class
        let styleActive = this.props.TradeExecutionSummaryStore.TradeExecutionSelectedStatuses.find(
          (x) =>
            x.tradeExecutionStatusName ===
              tradeExecutionClickable.tradeExecutionStatusName && x.active
        )
          ? style.active
          : "";

        return (
          <div key={tradeExecutionClickableIndex}>
            <div
              className={`${style.tradeExecutionStatusesListRow} ${style.link} ${styleActive}`}
              onClick={() =>
                this.props.TradeExecutionSummaryStore.SetTradeExecutionSelectedStatuses(
                  tradeExecutionClickable
                )
              }
            >
              <div>{tradeExecutionClickable.tradeExecutionStatusName}</div>
              <div
                className={`${style.tradeExecutionStatusesListCount} ${style.clickableStatusesCount}`}
              >
                {FormatNumberOnlyComma(tradeExecutionClickable.tradeExecutionStatusCount)}
              </div>
            </div>
          </div>
        );
      }
    );

    return (
      <div>
        <Typography className={`sidebarHeadingText`}>
          Trades
        </Typography>
        {tradeExecutionStatusesListClickableHtml}
        {<div className={`${style.divider}`}></div>}
      </div>
    );
  }

  render() {
    return <div>{this.renderTradeExecutionStatuses()}</div>;
  }
}

export default inject("TradeExecutionSummaryStore")(
  observer(TradeExecutionStatusesClickable)
);
