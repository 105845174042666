import SERVICES from "~/src/services";
import axiosObj from "axios";
import notifications from "~/src/utils/notifications";
import axiosRetry from "axios-retry";
import { SAVED_LAYOUT_API_ENUM } from "~/src/constants/TradeExecutionSummary";

//Add Axios Retry
let axios = axiosObj.create();
axiosRetry(axios, {
  retries: 2,
  shouldResetTimeout: true,
  retryDelay: () => {
    return SERVICES.RETRY_DELAY;
  },
  retryCondition: (error) => {
    if (error.toString().includes("timeout")) {
      notifications.ShowErrorMessage("Status update timed out, retrying...");
    }
    return true;
  },
});

axios.defaults.timeout = SERVICES.API_TIMEOUT;

const CONTENT_TYPE_APPL_JSON = "application/json";

export const GetSearchCriteriaListAPI = (token) => {
  return axios({
    method: "GET",
    timeout: SERVICES.API_TIMEOUT,
    url:
      SERVICES.API_ROOT().microServiceUrl +
      `/trade-execution-summaries/v1.0/searchWidget`,
    headers: {
      "Content-Type": CONTENT_TYPE_APPL_JSON,
      Authorization: `Bearer ${token}`,
    },
    config: {
      cache: "default",
    },
  });
};

export const GetTradeExecStatusCountAPI = (
  token,
  marketDate,
  searchCriteria
) => {
  var data = JSON.stringify({
    marketDate: marketDate,
    searchCriteria: searchCriteria,
  });

  return axios({
    method: "POST",
    timeout: SERVICES.API_TIMEOUT,
    url:
      SERVICES.API_ROOT().microServiceUrl +
      `/trade-execution-summaries/v1.0/searchTradeExecStatusCount`,
    headers: {
      "Content-Type": CONTENT_TYPE_APPL_JSON,
      Authorization: `Bearer ${token}`,
    },
    config: {
      cache: "default",
    },
    data: data,
  });
};

export const GetTradeExecStatusDetailsAPI = (
  token,
  page,
  size,
  marketDate,
  searchCriteria,
  executionStatus
) => {
  var data = JSON.stringify({
    page: page,
    size: size,
    marketDate: marketDate,
    searchCriteria: searchCriteria,
    executionStatus: executionStatus,
  });

  return axios({
    method: "POST",
    timeout: SERVICES.API_TIMEOUT,
    url:
      SERVICES.API_ROOT().microServiceUrl +
      `/trade-execution-summaries/v1.0/searchTradeExecDetails`,
    headers: {
      "Content-Type": CONTENT_TYPE_APPL_JSON,
      Authorization: `Bearer ${token}`,
    },
    config: {
      cache: "default",
    },
    data: data,
  });
};

export const GetTESSavedLayoutAPI = (token) => {
  return axios({
    method: "GET",
    url:
      SERVICES.API_ROOT().openEnvUrl +
      `/openenv/api/customization/type/${SAVED_LAYOUT_API_ENUM.customizationType}/page/${SAVED_LAYOUT_API_ENUM.page}`,
    headers: {
      "Content-Type": CONTENT_TYPE_APPL_JSON,
      Authorization: `Bearer ${token}`,
      Caller: "internal",
    },
    config: {
      cache: "default",
    },
  });
};

export const PostSaveAsLayoutAPI = (token, savedLayout) => {
  return axios({
    method: "POST",
    url: SERVICES.API_ROOT().openEnvUrl + `/openenv/api/customization`,
    data: savedLayout,
    headers: {
      "Content-Type": CONTENT_TYPE_APPL_JSON,
      Authorization: `Bearer ${token}`,
      Caller: "internal",
    },
    config: {
      cache: "default",
    },
  });
};

export const GetBrandConfigurationAPI = (token, handle) => {
  return axios({
    method: "GET",
    url:
      SERVICES.API_ROOT().openEnvUrl +
      `/openenv/api/advisors/${handle}/configurations`,
    headers: {
      "Content-Type": CONTENT_TYPE_APPL_JSON,
      Authorization: `Bearer ${token}`,
      Caller: "internal",
    },
    config: {
      cache: "default",
    },
  });
};
