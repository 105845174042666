import numeral from "numeral";
import { PRECISION_ENUM } from "~/src/constants/TradeExecutionSummary";

//Helper Methods
const GetCurrencySign = (currencyCode = "USD") => {
  let currencySign = "$";
  if (currencyCode === "USD") {
    currencySign = "$";
  }
  return currencySign;
};

export const PercentageConverter = (value) => {
  return isNaN(Number(value))
    ? 0
    : Number(Number(value * 100).toFixed(PRECISION_ENUM.AMOUNT));
};

//FORMAT
export const FormatAmount = (amount, currencyCode) => {
  amount = amount == null ? 0 : amount;
  return numeral(amount).format(`${GetCurrencySign(currencyCode)}0,0.00`);
};

export const FormatGainLossAmount = (gainLoss) => {
  // display negative numbers inside parentheses (no negative sign)
  // if amount is 0, display blank

  const amount = gainLoss ? gainLoss.amount : 0;
  const currencyCode = gainLoss ? gainLoss.currencyCode : "";

  let formattedAmount = amount
    ? FormatAmount(Math.abs(amount), currencyCode)
    : "";

  if (amount < 0) {
    formattedAmount = `(${formattedAmount})`;
  }

  return formattedAmount;
};

export const FormatNumber = (number, precision = PRECISION_ENUM.QUANTITY) => {
  let decimal = "0".repeat(precision);
  return numeral(number).format(`0,0.${decimal}`);
};

export const FormatNumberOnlyComma = (number) => {
  return numeral(number).format(`0,0`);
};

export const FetchDecimal = (number, precision = PRECISION_ENUM.AMOUNT) => {
  let decimal = "0".repeat(precision);
  return numeral(number).format(`.${decimal}`);
};

export const FormatPercentage = (pct) => {
  return numeral(pct / 100).format("0.00%");
};

export const FormatAmountColor = (amount) => {
  let formattedAmount = amount ? amount : 0;
  return formattedAmount < 0 ? "error" : "inherit";
};

export const FormatDate = (dateStr) => {
  let formattedDate = "";
  if (dateStr) {
    let d = new Date(dateStr);
    formattedDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  }

  return formattedDate;
};
