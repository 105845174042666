import SERVICES from "~/src/services/";
import axios from "axios";

axios.defaults.timeout = SERVICES.API_TIMEOUT;
const CONTENT_TYPE_APPL_JSON = "application/json";

export const CallLoginAPI = (userName, password, channel) => {
  const authHeader = btoa(`${userName}:${password}`);
  return axios({
    method: "GET",
    url: SERVICES.API_ROOT().openEnvUrl + "/openenv/api/auth/login",
    headers: {
      "X-channel": channel,
      "Content-Type": "application/json",
      Authorization: `Basic ${authHeader}`,
      Caller: "internal",
    },
    config: {
      cache: "default",
    },
  });
};

export const CallTokenAPI = (opaqueToken) => {
  return axios({
    method: "GET",
    url:
      SERVICES.API_ROOT().openEnvUrl +
      `/openenv/api/auth/logintoken?token=${opaqueToken}`,
    config: {
      cache: "default",
    },
  });
};

export const CallLogoutAPI = (token) => {
  return axios({
    method: "GET",
    url: SERVICES.API_ROOT().openEnvUrl + `/openenv/api/auth/logout`,
    headers: {
      "Content-Type": CONTENT_TYPE_APPL_JSON,
      Authorization: `Bearer ${token}`,
    },
    config: {
      cache: "default",
    },
  });
};
