import * as mobx from "mobx";
import {
  AddSessionStorage,
  FetchSessionStorage,
  RemoveSessionStorage,
} from "~/src/utils/storage";
import Variables from "~/src/variables/";
import {
  TRADE_EXECUTION_CLICKABLE_STATUSES_OBJECT_ENUM,
  TRADE_STATUS_REFRESH_TIME_ENUM,
  SEARCH_CRITERIA_ENUM,
  SAVED_LAYOUT_API_ENUM,
} from "~/src/constants/TradeExecutionSummary";
const { action, extendObservable } = mobx;

class TradeExecutionSummaryStoreActions {
  //Set API userSelect:
  @action SetOpenEnvUrl(openEnvUrl) {
    //If Running In VDI && openEnvUrl contains localhost then change openEnvUrl
    if (
      this.RootStore.IsProdEnv &&
      openEnvUrl &&
      openEnvUrl.includes("//localhost")
    ) {
      openEnvUrl = window.location.origin;
    }
    //Save in Session Storage
    AddSessionStorage(
      Variables.TESSessionStorage,
      Variables.OpenEnvUrl,
      openEnvUrl
    );
  }

  @action SetMicroServiceUrl(microServiceUrl) {
    //If Running In VDI && MicroServiceUrl contains localhost then change MicroServiceUrl
    if (
      this.RootStore.IsProdEnv &&
      microServiceUrl &&
      microServiceUrl.includes("//localhost")
    ) {
      microServiceUrl = window.location.origin;
    }
    //Save in Session Storage
    AddSessionStorage(
      Variables.TESSessionStorage,
      Variables.MicroServiceUrl,
      microServiceUrl
    );
  }

  RefreshAgGridView() {
    if (this.AgGrid && this.AgGrid.gridApi) {
      this.AgGrid.gridApi.refreshView();
    }
  }

  //AG GRID ROW DATA & COL DEF
  @action SetAgGridInstanceToStore(agGridInstance) {
    this.AgGrid = agGridInstance;
  }

  @action SetServerSideDataSource() {
    if (this.AgGrid && this.AgGrid.gridApi) {
      this.AgGridCurrentPage = 1;
      this.AgGrid.gridApi.setServerSideDatasource(this.ServerSideDataSource);
    }
  }

  @action SetIsPaginationReady(isPaginationReady) {
    this.IsPaginationReady = isPaginationReady;
  }

  @action SetAgGridCurrentPage(currentPage) {
    this.AgGridCurrentPage = currentPage;
  }

  @action HideOverlay() {
    if (this.AgGrid && this.AgGrid.gridApi) {
      this.AgGrid.gridApi.hideOverlay();
    }
  }

  @action ShowCustomAgGridOverlay() {
    if (this.AgGrid && this.AgGrid.gridApi) {
      this.AgGrid.gridApi.showLoadingOverlay();
    }
  }

  @action ShowNoRowsAgGridOverlay() {
    if (this.AgGrid && this.AgGrid.gridApi) {
      this.AgGrid.gridApi.showNoRowsOverlay();
    }
  }

  @action SetIsGridButtonsDisabled(isGridButtonsDisabled) {
    this.IsGridButtonsDisabled = isGridButtonsDisabled;
  }

  //Trade Execution Statuses
  @action GetTotalRecords() {
    return this.GetTradeExecutionActiveStatus
      ? this.GetTradeExecutionActiveStatus.tradeExecutionStatusCount
      : 0;
  }

  @action TradeExexStatusSortRows(params, rows) {
    let sortedRows = rows;
    //Sort If Exist
    if (params.request.sortModel.length > 0) {
      let sortModel = params.request.sortModel[0];
      sortedRows.sort((a, b) => {
        // Use toUpperCase() to ignore character casing
        const A = a[sortModel.colId];
        const B = b[sortModel.colId];
        let comparison = 0;
        if (A > B) {
          comparison = 1;
        } else if (A < B) {
          comparison = -1;
        }
        if (sortModel.sort === "asc") {
          return comparison;
        } else {
          return comparison * -1;
        }
      });
    }

    return sortedRows;
  }

  @action TradeExecStatusSuccess(params, rowData, totalRecords) {
    this.SetIsGridButtonsDisabled(false);
    this.SetIsTradeExecutionStatusTimerPause(false);
    if (
      rowData.data &&
      rowData.data.orderDetails &&
      rowData.data.orderDetails.tradeOrderExecutionDetails.length > 0
    ) {
      let rows = rowData.data.orderDetails.tradeOrderExecutionDetails;
      rows = this.TradeExexStatusSortRows(params, rows);
      params.successCallback(rows, totalRecords);
      this.SetIsGridButtonsDisabled(false);
      this.HideOverlay();
    } else {
      params.successCallback([], 0);
      this.SetAgGridCurrentPage(0);
      this.SetIsGridButtonsDisabled(true);
      if (this.IsEachSearchCriteriaAll) {
        this.ShowNoRowsAgGridOverlay();
      } else {
        this.ShowCustomAgGridOverlay();
      }
    }
  }

  @action SetTradeExecutionSelectedStatuses(tradeExecutionSelectedStatus) {
    //If Status Not Exist Then Add and Make it Active
    if (
      this.TradeExecutionSelectedStatuses.filter(
        (x) => x.tradeExecutionStatusName === tradeExecutionSelectedStatus
      ).length === 0
    ) {
      //Make all existing one active:false:
      this.TradeExecutionSelectedStatuses.forEach(
        (tradeExecutionStatus) => (tradeExecutionStatus.active = false)
      );
      //Add New Tab and make it active
      this.TradeExecutionSelectedStatuses.push({
        tradeExecutionStatus: tradeExecutionSelectedStatus.tradeExecutionStatus,
        tradeExecutionStatusName:
          tradeExecutionSelectedStatus.tradeExecutionStatusName,
        tradeExecutionStatusCount:
          tradeExecutionSelectedStatus.tradeExecutionStatusCount,
        active: true,
      });
    } else {
      //If Status Already Exist Then Set All False and Selected One True with updated Count
      this.TradeExecutionSelectedStatuses.forEach(
        (tradeExecutionStatus, tradeExecutionStatusIndex) => {
          if (
            tradeExecutionStatus.tradeExecutionStatusName ===
            tradeExecutionSelectedStatus
          ) {
            //Selected Tab and make it active
            tradeExecutionStatus.active = true;
            tradeExecutionStatus.tradeExecutionStatusCount =
              tradeExecutionSelectedStatus.tradeExecutionStatusCount;
          } else {
            //Make all existing one active:false:
            tradeExecutionStatus.active = false;
          }
        }
      );
    }
    this.SetServerSideDataSource();
  }

  @action SetFirstTradeExecutionStatus() {
    let tradeExecutionStatusesListClickable = this.GetTradeExecutionStatusesList
      .tradeExecutionStatusesClickable;
    //If TradeExecutionSelectedStatuses Is Empty Then Set With 1st Options
    if (
      this.TradeExecutionSelectedStatuses.length === 0 &&
      tradeExecutionStatusesListClickable.length > 0
    ) {
      let firstClickableStatuses = tradeExecutionStatusesListClickable.find(
        (x) =>
          x.tradeExecutionStatusName ===
          TRADE_EXECUTION_CLICKABLE_STATUSES_OBJECT_ENUM.REJECTED
      );
      this.SetTradeExecutionSelectedStatuses(firstClickableStatuses);
    }
  }

  @action RefreshTradeExecutionStatus() {
    this.ResetTradeExecutionStatusTimer();
    this.GetTradeExecutionStatuses();
  }

  @action SetTradeExecutionStatusTimer(tradeExecutionStatusTimer) {
    this.TradeExecutionStatusTimer = tradeExecutionStatusTimer;
  }

  @action SetIsTradeExecutionStatusTimerPause(
    isTradeExecutionStatusTimerPause
  ) {
    if (isTradeExecutionStatusTimerPause) {
      this.ResetTradeExecutionStatusTimer();
    }
    this.IsTradeExecutionStatusTimerPause = isTradeExecutionStatusTimerPause;
  }

  @action ResetTradeExecutionStatusTimer() {
    this.TradeExecutionStatusTimer = TRADE_STATUS_REFRESH_TIME_ENUM;
  }

  @action StartTradeExecutionStatusTimer() {
    this.TradeExecutionStatusTimer = this.TradeExecutionStatusTimer - 1;
    //Reset Timer If Reached Zero
    if (this.TradeExecutionStatusTimer === 0) {
      this.ResetTradeExecutionStatusTimer();
      this.GetTradeExecutionStatuses();
    }
  }

  //Trade Execution Filters (SEARCH CRITERIA)
  @action CloseFiltersOverlay() {
    //Revert Search Criteria Dropdown to Last Search Criteria Drodown Applied Values
    this.SearchCriteriaDropDownValues = this.SearchCriteriaDropDownAppliedValues;
    this.IsFilterOverlayOpen = false;
    this.IsSearchCriteriaSaveToLayout = false;
  }

  @action OpenFiltersOverlay() {
    this.IsSearchCriteriaSaveToLayout = false;
    this.IsFilterOverlayOpen = true;
  }

  @action OnChangeSearchCriteriaDropDownValues(searchType, values) {
    //Filter Changed Dropdown By Search Type
    this.SearchCriteriaDropDownValues = this.SearchCriteriaDropDownValues.filter(
      (x) => x.searchType !== searchType
    );
    //If Selected All Then Unselect Others Options
    let searchValuesAllExist =
      values.length > 0
        ? values.find((x) => x.label.toLowerCase() === SEARCH_CRITERIA_ENUM.ALL)
        : null;
    if (searchValuesAllExist) {
      searchValuesAllExist.selected = true;
      values = [searchValuesAllExist];
    }
    //If Values Exist Then Push To SearchCriteria Dropdown
    if (values.length > 0) {
      //Add Selected Flag True
      values.forEach((x) => (x.selected = true));
      // //Get Latest Change Dropdown
      this.SearchCriteriaDropDownValues.push({
        searchType: searchType,
        searchTypeValues: values,
      });
    }
  }

  @action ApplySearchCriteriaDropDownValues() {
    //SetSerachCriteriaDropDownValuesToAllIfNoOptionSelected
    this.SetSerachCriteriaDropDownValuesToAllIfNoOptionSelected();
    //Get Search Criteria Dropdown to Assign to Applied Values
    this.SearchCriteriaDropDownAppliedValues = this.SearchCriteriaDropDownValues;
    //Save to Layout If CheckBox Checked In Overlay
    if (this.IsSearchCriteriaSaveToLayout) {
      this.PostSaveLayout();
    }
    AddSessionStorage(
      Variables.TESSessionStorage,
      Variables.SearchCriteria,
      this.SearchCriteriaDropDownAppliedValues
    );
    this.IsFilterOverlayOpen = false;
    this.IsSearchCriteriaSaveToLayout = false;
    this.GetTradeExecutionStatuses();
  }

  @action SetSearchCriteriaList(searchCriteriaListResponse) {
    if (searchCriteriaListResponse && searchCriteriaListResponse.data) {
      this.SearchCriteriaList = searchCriteriaListResponse.data;
      //Remove Empty Entries
      this.SearchCriteriaList.searchCriteria = this.SearchCriteriaList.searchCriteria.filter(
        (x) => x.searchTypeValues.length > 0
      );
      //Set Search Value Options If Only 1 Exist Due to Single Option Disable In Dropdown
      this.SetSearchCriteriaSingleOption();
    }
  }

  @action SetSearchCriteriaDropDownValuesFromSessionStorage() {
    let searchCriteriaSessionStorage = FetchSessionStorage(
      Variables.TESSessionStorage,
      Variables.SearchCriteria
    );
    if (this.SearchCriteriaSavedLayoutFromAPI) {
      const mapped = this.MapSearchCriteriaValues(
        this.SearchCriteriaList.searchCriteria,
        JSON.parse(this.SearchCriteriaSavedLayoutFromAPI.value)
      );
      this.SearchCriteriaDropDownValues = mapped;
      this.SearchCriteriaDropDownAppliedValues = mapped;
      this.GetTradeExecutionStatuses();
    } else if (searchCriteriaSessionStorage) {
      const mapped = this.MapSearchCriteriaValues(
        this.SearchCriteriaList.searchCriteria,
        searchCriteriaSessionStorage
      );
      this.SearchCriteriaDropDownValues = mapped;
      this.SearchCriteriaDropDownAppliedValues = mapped;
      this.GetTradeExecutionStatuses();
    } else {
      this.ApplySearchCriteriaDropDownValues();
    }
  }

  @action SetSerachCriteriaDropDownValuesToAllIfNoOptionSelected() {
    //Set Search Criteria Dropdown All If Options is Not Selected
    let searchCriteriaAllArr = [];
    this.SearchCriteriaList.searchCriteria.forEach((searchCriteria) => {
      let searchCriteriaDropdownValues = this.SearchCriteriaDropDownValues.find(
        (x) => x.searchType === searchCriteria.searchType
      );
      //If DropDown Does Not Selected
      if (!searchCriteriaDropdownValues) {
        let searchCriteriaAllOption = searchCriteria.searchTypeValues.filter(
          (x) => x.label.toLowerCase() === SEARCH_CRITERIA_ENUM.ALL
        );
        //Selected True
        searchCriteriaAllOption.forEach((x) => (x.selected = true));
        //If Search All Option Exist Then Push It
        if (searchCriteriaAllOption.length > 0)
          searchCriteriaAllArr.push({
            searchType: searchCriteria.searchType,
            searchTypeValues: searchCriteriaAllOption,
          });
        //If Search Criteria has Only Option
      } else if (searchCriteria.searchTypeValues.length === 1) {
        searchCriteriaAllArr.push({
          searchType: searchCriteria.searchType,
          searchTypeValues: searchCriteria.searchTypeValues,
        });
      } else {
        searchCriteriaAllArr.push(searchCriteriaDropdownValues);
      }
    });
    this.SearchCriteriaDropDownValues = searchCriteriaAllArr;
  }

  @action SetIsSearchCriteriaSaveToLayout() {
    this.IsSearchCriteriaSaveToLayout = !this.IsSearchCriteriaSaveToLayout;
  }

  @action SetSearchCriteriaSavedLayoutFromAPI(tesSavedLayoutResponse) {
    if (
      tesSavedLayoutResponse.data &&
      tesSavedLayoutResponse.data.trade_monitor_saved_layout_default
    ) {
      this.SearchCriteriaSavedLayoutFromAPI =
        tesSavedLayoutResponse.data.trade_monitor_saved_layout_default[0];
    }
  }

  @action GetNewSavedLayoutObject() {
    let savedLayoutObject = {
      customizationType: SAVED_LAYOUT_API_ENUM.customizationType,
      error: false,
      handle: null,
      name: "trade_monitor_saved_layout_default",
      options: [],
      ownerId: null,
      ownerType: null,
      page: SAVED_LAYOUT_API_ENUM.page,
      value: JSON.stringify(this.SearchCriteriaDropDownValues),
    };
    //If SavedLayout Already Exist
    if (this.SearchCriteriaSavedLayoutFromAPI) {
      savedLayoutObject = this.SearchCriteriaSavedLayoutFromAPI;
      savedLayoutObject.value = JSON.stringify(
        this.SearchCriteriaDropDownValues
      );
    }
    return savedLayoutObject;
  }

  @action GetMapHash(searchCriteria, receivedValues) {
    let hash = {};
    //Get Recieved Values By Search Type for Selected
    let receivedValuesBySearchType = receivedValues.find(
      (r) => r.searchType === searchCriteria.searchType
    );

    searchCriteria.searchTypeValues.forEach((v) => {
      //Get Recieved Values By Search Type Value Object
      let receivedSearchTypeValue = receivedValuesBySearchType.searchTypeValues.find(
        (x) => x.value === v.value
      );
      //If receivedSearchTypeValue exist get received Value Selected Value
      v.selected = receivedValuesBySearchType.searchTypeValues.find(
        (x) => x.value === v.value
      )
        ? receivedSearchTypeValue.selected
        : v.selected;
      hash[v.value] = v;
    });

    return hash;
  }

  @action GetAllObjectFromHash(hash) {
    //If Hash[v.value] undefined then return all value
    let findAllObject = "";
    for (let key in hash) {
      if (hash[key].label.toLowerCase() === SEARCH_CRITERIA_ENUM.ALL) {
        findAllObject = hash[key];
      }
    }
    return findAllObject;
  }

  @action MapSearchCriteriaValues(options, receivedValues) {
    const mapped = [];
    (receivedValues || []).forEach((s) => {
      const searchCriteria = options.find((o) => o.searchType === s.searchType);
      if ((s.searchTypeValues || []).length > 0 && searchCriteria) {
        const hash = this.GetMapHash(searchCriteria, receivedValues);

        mapped.push({
          searchType: s.searchType,
          searchTypeValues: s.searchTypeValues.map((v) => {
            //Check If Hash[v.value] is not null
            return hash[v.value]
              ? hash[v.value]
              : this.GetAllObjectFromHash(hash);
          }),
        });
      }
    });
    return mapped;
  }

  @action SetSearchCriteriaSingleOption() {
    //Set Search Value Options If Only 1 Exist Due to Single Option Disable In Dropdown
    let searchCriteriaDropdownArr = [];
    this.SearchCriteriaList.searchCriteria.forEach((searchCriteria) => {
      if (searchCriteria.searchTypeValues.length === 1) {
        searchCriteriaDropdownArr.push({
          searchType: searchCriteria.searchType,
          searchTypeValues: searchCriteria.searchTypeValues.forEach(
            (x) => (x.selected = true)
          ),
        });
      }
    });
    this.SearchCriteriaDropDownValues = searchCriteriaDropdownArr;
    this.searchCriteriaDropDownAppliedValues = searchCriteriaDropdownArr;
  }

  @action ClearSearchCriteriaDropDownValues() {
    this.SearchCriteriaDropDownValues = [];
    this.SearchCriteriaDropDownAppliedValues = [];

    //Remove Search Criteria From Session Storage
    RemoveSessionStorage(Variables.TESSessionStorage, Variables.SearchCriteria);

    //Set SetSearchCriteriaSingleOption
    this.SetSearchCriteriaSingleOption();

    //SetSerachCriteriaDropDownValuesToAllIfNoOptionSelected
    this.SetSerachCriteriaDropDownValuesToAllIfNoOptionSelected();
    this.ApplySearchCriteriaDropDownValues();
  }

  @action ResetStore() {
    extendObservable(this, {
      SearchCriteriaList: {},
      SearchCriteriaDropDownValues: [],
      SearchCriteriaDropDownAppliedValues: [],
      TradeExecutionStatusesList: {},
      TradeExecutionSelectedStatuses: [],
      TradeExecutionStatusTimer: TRADE_STATUS_REFRESH_TIME_ENUM,
      IsGridButtonsDisabled: false,
      GridLoading: false,
    });
  }

  @action SetBrandConfiguration(brandConfigurationResponse) {
    if (
      brandConfigurationResponse &&
      brandConfigurationResponse.status === 200 &&
      brandConfigurationResponse.data
    ) {
      //Set BrandConfiguration
      this.BrandConfiguration = brandConfigurationResponse.data;
    }
  }
}

export default TradeExecutionSummaryStoreActions;
