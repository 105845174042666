import React, { Component } from "react";
import ReactDOM from "react-dom";
import { configure } from "mobx";
import { Provider } from "mobx-react";
import autobind from "react-autobind";
import "mobx-react-lite/batchingForReactDom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import routes from "~/src/routes";
import stores from "~/src/stores";
import Layout from "~/src/components/Common/Layout";
import PrivateRoute from "~/src/components/Common/PrivateRoute";
import PublicRoute from "~/src/components/Common/PublicRoute";
import Login from "~/src/containers/Login";
import Dashboard from "~/src/containers/Dashboard";
import "~/src/global/libs/fontawesome/css/all.css";
import "~/src/global/css/global.css";

configure({ enforceActions: "observed" });
const RootStore = new stores();

class App extends Component {
  constructor(props) {
    super(props);
    autobind(this);
  }
  componentDidMount() {
    window.onbeforeunload = this.handleWindowBeforeUnload;
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  handleWindowBeforeUnload = async () => {
    //Avoid on Login Screen
    if (RootStore.AuthenticationStore.IsLoggedIn) {
      setTimeout(async () => {
        //If Click Cancel Then It Will Init App Again
        await RootStore.TradeExecutionSummaryStore.GetInit();
      }, 1000);
    }
    return "Are you sure want to leave....";
  };

  render() {
    return (
      <Provider {...RootStore}>
        <Router>
          <Switch>
            <Layout>
              <PublicRoute
                exact
                strict
                path={routes.login}
                component={Login}
                restricted={true}
              />
              <PrivateRoute exact path={routes.tes} component={Dashboard} />
            </Layout>
          </Switch>
        </Router>
      </Provider>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
