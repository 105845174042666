/*API ENUMS*/
export const QUERY_STRING_ENUM = {
  TES_SESSION: "init",
};

export const TRADE_STATUS_REFRESH_TIME_ENUM = 90;

export const PRECISION_ENUM = {
  QUANTITY: 4,
  AMOUNT: 2,
};

//AG GRID + WST ENUMS
//To Make Specific Statuses Clickable
export const TRADE_EXECUTION_CLICKABLE_STATUSES_ENUM = [
  "Rejected",
  "Execution Pending",
];

export const TRADE_EXECUTION_CLICKABLE_STATUSES_OBJECT_ENUM = {
  REJECTED: "Rejected",
  EXECUTION_PENDING: "Execution Pending",
};

export const SEARCH_CRITERIA_ENUM = {
  ALL: "all",
};

export const COLUMNS_ENUM = {
  ACCOUNT_NUMBER: "Account number",
  TICKER: "Ticker",
  BUY_SELL: "Buy/Sell",
  SHARES: "Shares",
  AMOUNT: "Amount",
  EXPIRATION_DATE: "Expiration date",
  PRICE_QUALIFIER: "Price qualifier",
  ADVISOR: "Advisor",
  SUBMITTED_BY: "Submitted by",
  STATUS: "Status",
  EXCEPTION_DETAILS: "Exception Details",
};

export const FIELD_ENUM = {
  ACCOUNT_NUMBER: "custodianAccountNum",
  TICKER: "ticker",
  BUY_SELL: "orderAction",
  SHARES: "orderQuantity",
  AMOUNT: "orderAmount",
  EXPIRATION_DATE: "expirationDate",
  PRICE_QUALIFIER: "priceQualifier",
  ADVISOR: "advisorName",
  SUBMITTED_BY: "orderCreatedBy",
  STATUS: "orderStatusDesc",
  EXCEPTION_DETAILS: "exceptionDescription",
};

export const EXCEL_EXPORT_ENUM = {
  PROJECT:"trade-monitor"
}

export const SAVED_LAYOUT_API_ENUM = {
  customizationType: 14,
  page: "trade_monitor_saved_layout",
};
