import React from "react";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/jelly.css";

import CssBaseline from "@material-ui/core/CssBaseline";
import {
  ThemeProvider,
  generateTheme,
  combineThemeDescription,
} from "@envestnet/envreact-component-library";
import style from "./style.module.css";
import Header from "~/src/components/Common/Header";
import Footer from "~/src/components/Common/Footer";
import NotificationsTemplate from "~/src/components/Common/NotificationsTemplate";

const theme = generateTheme(combineThemeDescription());

class Layout extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <div className={style.layout}>
            <Header />
            <div className={style.wrapper}>{this.props.children}</div>
            <Footer />
            <Alert stack={true} contentTemplate={NotificationsTemplate} />
          </div>
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

export default Layout;
