import React, { Component } from "react";
import { CircularProgress } from "@envestnet/envreact-component-library";
import style from "./style.module.css";

export default class CustomLoadingCellRenderer extends Component {
  render() {
    return (
      <div
        className="ag-custom-loading-cell"
        style={{ lineHeight: "20px", display: "flex", paddingTop: "5px" }}
      >
        <CircularProgress color="primary" size={20} variant="indeterminate" />
        <span className={style.loadingMsg}>Loading...</span>
      </div>
    );
  }
}
