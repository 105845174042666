import { FetchSessionStorage } from "~/src/utils/storage";
import Variables from "~/src/variables/";

const Env = {
  OPENENV_URL: "http://ump.dev-env.us-south-01.envestnet.cloud",
  MICROSERVICE_URL: "http://microservice.dev-env.us-south-01.envestnet.cloud:10009/magnum/api",
  // OPENENV_URL: "http://envdev0031:8082",
  // MICROSERVICE_URL: "http://envdev0031:8081",
};

//Temp Login Info For Dev Tesing
const LoginDetails = {
  username: "",
  password: "",
  channel: "",
};

//GET API ROOT
let GetApiRoot = () => {
  //Get openEnvUrl If Exist In Storage
  let openEnvUrl = FetchSessionStorage(
    Variables.TESSessionStorage,
    Variables.OpenEnvUrl
  );

  //Get microServiceUrl If Exist In Storage
  let microServiceUrl = FetchSessionStorage(
    Variables.TESSessionStorage,
    Variables.MicroServiceUrl
  );

  //If openEnvUrl doesnot exist then use hard coded one.
  if (!openEnvUrl) {
    //If Running Locally Without UseLink TES Session
    openEnvUrl = Env.OPENENV_URL;
  }

  //If microServiceUrl doesnot exist then use hard coded one.
  if (!microServiceUrl) {
    //If Running Locally Without UseLink TES Session
    microServiceUrl = Env.MICROSERVICE_URL;
  }

  return { openEnvUrl, microServiceUrl };
};

//EXPORT DEFAULT
export default {
  API_ROOT: GetApiRoot,
  API_TIMEOUT: 30000,
  RETRY_DELAY: 1000,
  USERNAME: LoginDetails.username,
  PASSWORD: LoginDetails.password,
  CHANNEL: LoginDetails.channel,
};
