import React from "react";
import { observer, inject } from "mobx-react";
import style from "./style.module.css";
import Logo from "~/src/components/Common/Logo";
import { Button } from "@envestnet/envreact-component-library";

export default inject("AuthenticationStore")(
  observer((props) => {
    let logOut = () => {
      props.AuthenticationStore.LogOutAction();
    };

    let renderLogoutButton = () => {
      let logOutButton = null;
      if (props.AuthenticationStore.IsLoggedIn) {
        logOutButton = (
          <Button
            buttonStyle="link"
            iconOnly={<><i href="#" className={`far fa-times ${style.log_out_link}`} /></>}
            name="logout"
            onClick={() => logOut()}
          />
        );
      }

      return logOutButton;
    };

    return (
      <div className={style.header}>
        <Logo />
        <div>{renderLogoutButton()}</div>
      </div>
    );
  })
);
