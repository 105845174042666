import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Box } from "@material-ui/core";
import { Modal, Button } from "@envestnet/envreact-component-library";
import style from "./style.module.css";

class LogoutOverlay extends Component {
  closeModal() {
    this.props.AuthenticationStore.SetIsLogoutModalOpen(false);
  }

  logoutCloseSession() {
    window.onbeforeunload = null;
    this.props.AuthenticationStore.LogoutAndClearSession();
    window.close();
  }

  render() {
    return (
      <Modal
        open={this.props.AuthenticationStore.IsLogoutModalOpen}
        onClose={() => this.closeModal()}
      >
        <Box>
          <div>Are you sure you want to close the Trade Status Monitor?</div>
          <Box className={`${style.logoutOverlayButton}`}>
            <Button
              name="Confirm"
              onClick={() => this.logoutCloseSession()}
            ></Button>
            <Button
              name="Cancel"
              buttonStyle="secondary"
              onClick={() => this.closeModal()}
            ></Button>
          </Box>
        </Box>
      </Modal>
    );
  }
}

export default inject("AuthenticationStore")(observer(LogoutOverlay));
