import React, { Component } from "react";
import { observer, inject } from "mobx-react";
import { Typography, Box, Grid } from "@material-ui/core";
import { Button } from "@envestnet/envreact-component-library";
import AgGrid from "~/src/components/TradeExecutionSummary/AgGrid";
import style from "./style.module.css";

class TradeExecutionDetails extends Component {
  renderTopCurrentTotalRecords() {
    let currentRecords =
      this.props.TradeExecutionSummaryStore.AgGridCurrentPage *
      this.props.TradeExecutionSummaryStore.AgGridPageSize;
    let totalRecords = this.props.TradeExecutionSummaryStore
      .GetTradeExecutionActiveStatus.tradeExecutionStatusCount;

    if (currentRecords > totalRecords) {
      currentRecords = totalRecords;
    }
    return (
      <span className={style.tradeExecutionDetailsTopCount}>
        {currentRecords} of {totalRecords}
      </span>
    );
  }

  renderAgGrid() {
    let activeTradeExecutionSelectedStatuses = this.props
      .TradeExecutionSummaryStore.GetTradeExecutionActiveStatus;

    return activeTradeExecutionSelectedStatuses && this.props.TradeExecutionSummaryStore.IsTradeStatusAvailable ? (
      <div>
        <div className={style.tradeExecutionDetailsBox}>
          <div>
            <span className={style.tradeExecutionDetailsHeading}>
              {activeTradeExecutionSelectedStatuses.tradeExecutionStatusName}
            </span>
            {this.renderTopCurrentTotalRecords()}
            <Button
              name="Edit"
              buttonStyle="link"
              className={`sidebarHeaderLinkText`}
              onClick={() =>
                this.props.TradeExecutionSummaryStore.OpenFiltersOverlay()
              }
            />
          </div>
          <div className={style.tradeExecutionDetailsTopButtons}>
            <Button
              buttonStyle="secondary"
              name="Refresh"
              iconRight={<i className="far fa-sync-alt"></i>}
              disabled={
                this.props.TradeExecutionSummaryStore.IsGridButtonsDisabled
              }
              onClick={() =>
                this.props.TradeExecutionSummaryStore.SetServerSideDataSource()
              }
            />
            <Button
              buttonStyle="secondary"
              name="Export"
              onClick={() =>
                this.props.TradeExecutionSummaryStore.ExportAgGrid()
              }
              iconRight={<i className="far fa-file-export"></i>}
              disabled={
                this.props.TradeExecutionSummaryStore.IsGridButtonsDisabled
              }
            />
          </div>
        </div>
        <AgGrid />
      </div>
    ) : null;
  }

  renderDefaultView() {
    return (
      <Grid xs={6} item>
        <Box mt={3}>
          <i className="far fa-3x fa-th-list"></i>
        </Box>
        <Box mb={1}>
          <Typography className={`mainHeading`}>View details</Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            Click the Execution pending or Execution rejected trade status to
            see details.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            You can click Filter, and select from the available options, to see
            a more focused set of results.
          </Typography>
        </Box>
      </Grid>
    );
  }

  render() {
    return this.props.TradeExecutionSummaryStore.GetTradeExecutionActiveStatus
      ? this.renderAgGrid()
      : null;
  }
}

export default inject("TradeExecutionSummaryStore")(
  observer(TradeExecutionDetails)
);
