import { observable, flow } from "mobx";
import {
  CallLoginAPI,
  CallLogoutAPI,
} from "~/src/services/AuthenticationService";
import { FetchSessionStorage, DeleteSessionStorage } from "~/src/utils/storage";
import notifications from "~/src/utils/notifications";
import Variables from "~/src/variables/";
import AuthenticationStoreActions from "./AuthenticationStoreActions";

class AuthenticationStore extends AuthenticationStoreActions {
  constructor(RootStore) {
    super();
    this.RootStore = RootStore;
    this.TradeExecutionTimerInterval = [];
    this.UpdatedLoggedInStatus();
  }

  @observable IsLoggedIn;
  @observable IsLogoutModalOpen = false;

  UpdatedLoggedInStatus() {
    let token = FetchSessionStorage(
      Variables.TESSessionStorage,
      Variables.Token
    );
    this.IsLoggedIn = token !== undefined && token != null;
  }

  LoginAction = flow(function* (username, password, channel, use_link_login) {
    try {
      this.RootStore.LoadingOn();
      let response = yield CallLoginAPI(username, password, channel);
      if (response.status === 200) {
        var token = response.headers.token;

        //If Use Link Login
        if (use_link_login) {
          this.RootStore.TradeExecutionSummaryStore.LaunchWorksheetPopupByGeneratedQueryString(
            token,
            response.data.userHandle
          );
        } else {
          //Settoken in Session Storage
          this.SetToken(token);
          //SetUserHandle in Session Storage
          this.SetUserHandle(response.data.userHandle);

          this.UpdatedLoggedInStatus();
        }
        this.RootStore.LoadingOff();
      } else {
        this.RootStore.LoadingOff();
        notifications.ShowErrorMessage("Error :" + response.status);
      }
    } catch (error) {
      this.RootStore.LoadingOff();
      notifications.CloseAllMessages();
      notifications.ShowErrorMessage(notifications.FormatError(error));
    }
  });

  LogoutAndClearSession = flow(function* () {
    try {
      this.RootStore.LoadingOn();
      let response = yield CallLogoutAPI(
        this.RootStore.TradeExecutionSummaryStore.Token
      );
      if (response.status === 200) {
        DeleteSessionStorage(Variables.TESSessionStorage, Variables.Token);
        this.UpdatedLoggedInStatus();
        this.RootStore.TradeExecutionSummaryStore.ResetStore();
        this.RootStore.LoadingOff();
        this.TradeExecutionTimerInterval.forEach((timerInterval) => {
          clearInterval(timerInterval);
        });
        this.TradeExecutionTimerInterval = [];
        window.onbeforeunload = null;
        window.location.reload();
      } else {
        this.RootStore.LoadingOff();
        notifications.ShowErrorMessage("Error :" + response.status);
      }
    } catch (error) {
      this.RootStore.LoadingOff();
      notifications.CloseAllMessages();
      notifications.ShowErrorMessage(notifications.FormatError(error));
    }
  });
}

export default AuthenticationStore;
