import React, { Component } from "react";
import ReactDOM from "react-dom";
import { observer, inject } from "mobx-react";
import Spinner from "~/src/components/Common/Spinner";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "@ag-grid-community/react";
import { ModuleRegistry, AllModules } from "@ag-grid-enterprise/all-modules";
import { AllCommunityModules } from "@ag-grid-community/all-modules";
import { LicenseManager } from "@ag-grid-enterprise/core";
import { SimplePaginator as Pagination } from "@envestnet/envreact-component-library";
import { Button } from "@material-ui/core";
import CustomLoadingCellRenderer from "~/src/components/TradeExecutionSummary/CustomLoadingCellRenderer";
import "./style.css";
import style from "./style.module.css";

let companyName = "CompanyName=Envestnet";
let licensedApp = "LicensedApplication=UMP";
let licenseType = "LicenseType=SingleApplication";
let licensedConcDevCount = "LicensedConcurrentDeveloperCount=5";
let licensedProdInstCount = "LicensedProductionInstancesCount=1";
let assetReference = "AssetReference=AG-008903";
let expiryDate =
  "ExpiryDate=25_October_2021_[v2]_MTYzNTExNjQwMDAwMA==13bf6a7e0b5eaf163c0cce827fe71f84";

LicenseManager.setLicenseKey(
  `${companyName},${licensedApp},${licenseType},${licensedConcDevCount},${licensedProdInstCount},${assetReference},${expiryDate}`
);

ModuleRegistry.registerModules([
  AllModules,
  AllCommunityModules,
  // ColumnsToolPanelModule,
  // FiltersToolPanelModule,
  // RowGroupingModule
]);

class AgGrid extends Component {
  constructor(props) {
    super(props);

    props.TradeExecutionSummaryStore.SetAgGridInstanceToStore(this);
  }

  //Grid Methods
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    //Temp Bind to Window Object for Testing
    window.gridApi = this.gridApi;
    window.gridColumnApi = this.gridColumnApi;

    if (window.innerWidth > 1585) {
      //set fit columns:
      this.gridApi.sizeColumnsToFit();
    }

    //Set Pagination Ready
    this.props.TradeExecutionSummaryStore.SetIsPaginationReady(true);
  };

  getRowClass = (params) => {
    return `agRowClass`;
  };

  getRowStyle = (params) => {
    return { height: `40px` };
  };

  //Get Ag Grid Config
  getSideBarConfig() {
    return {
      toolPanels: [
        // {
        //   id: "columns",
        //   labelDefault: "Columns",
        //   labelKey: "columns",
        //   iconKey: "columns",
        //   toolPanel: "agColumnsToolPanel",
        //   toolPanelParams: {
        //     suppressPivotMode: true,
        //     suppressRowGroups: true,
        //     suppressValues: true,
        //   },
        // },
        // {
        //   id: "filters",
        //   labelDefault: "Filters",
        //   labelKey: "filters",
        //   iconKey: "filter",
        //   toolPanel: "agFiltersToolPanel",
        // },
      ],
    };
  }

  //Default Col Def
  getDefaultColDef() {
    return {
      singleClickEdit: true,
      enableValue: false,
      enableRowGroup: false,
      enablePivot: false,
      filter: true,
      resizable: true,
      sortable: false,
    };
  }

  getAgGridStyling() {
    return {
      opacity: this.props.TradeExecutionSummaryStore.GridLoading ? 0 : 1,
      position: this.props.TradeExecutionSummaryStore.GridLoading
        ? "absolute"
        : "relative",
    };
  }

  firstDataRenderedFunc() {
    if (
      this.props.TradeExecutionSummaryStore.RootStore.AuthenticationStore
        .IsLoggedIn &&
      this.props.TradeExecutionSummaryStore.RootStore.AuthenticationStore
        .TradeExecutionTimerInterval.length === 0
    ) {
      //Start Trade Execution Status Timer
      this.props.TradeExecutionSummaryStore.RootStore.AuthenticationStore.TradeExecutionTimerInterval.push(
        setInterval(() => {
          //If Status Time Not Pause
          if (
            !this.props.TradeExecutionSummaryStore
              .IsTradeExecutionStatusTimerPause
          ) {
            this.props.TradeExecutionSummaryStore.StartTradeExecutionStatusTimer();
          }
        }, 1000)
      );
    }
  }

  //Render
  renderAgGridPagination() {
    if (this.props.TradeExecutionSummaryStore.IsPaginationReady) {
      //Init
      let currentPage = this.props.TradeExecutionSummaryStore.AgGridCurrentPage;
      let totalRecords = this.props.TradeExecutionSummaryStore
        .GetTradeExecutionActiveStatus.tradeExecutionStatusCount;
      return (
        <div className={`${style.agGridPaginationWrapper}`}>
          <Pagination
            currentPage={currentPage}
            onPageSelect={(page) => {
              if (page >= 0) {
                this.gridApi.paginationGoToPage(page - 1);
                this.props.TradeExecutionSummaryStore.SetAgGridCurrentPage(
                  page
                );
              }
            }}
            pageSize={this.props.TradeExecutionSummaryStore.AgGridPageSize}
            recordControlEnabled
            totalRecordCount={totalRecords}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderAgGridSpinner() {
    if (this.props.TradeExecutionSummaryStore.GridLoading !== false) {
      return <Spinner />;
    } else {
      return null;
    }
  }

  //Render Events
  render() {
    let renderData = null;
    if (this.props.TradeExecutionSummaryStore.IsTradeStatusAvailable) {
      renderData = (
        <div>
          <div
            className={`ag-theme-balham ${style.agGrid}`}
            style={this.getAgGridStyling()}
          >
            <AgGridReact
              modules={AllModules}
              animateRows={false}
              cacheQuickFilter={false}
              suppressColumnVirtualisation={false}
              enableCellChangeFlash={true}
              rememberGroupStateWhenNewData={true}
              suppressScrollOnNewData={true}
              sideBar={this.getSideBarConfig()}
              stopEditingWhenGridLosesFocus={true}
              onGridReady={this.onGridReady}
              columnDefs={this.props.TradeExecutionSummaryStore.GetAgGridColDef}
              defaultColDef={this.getDefaultColDef()}
              pagination={true}
              paginationPageSize={
                this.props.TradeExecutionSummaryStore.AgGridPageSize
              }
              onPaginationChanged={this.onPaginationChanged}
              frameworkComponents={{
                customLoadingCellRenderer: CustomLoadingCellRenderer,
              }}
              loadingCellRenderer="customLoadingCellRenderer"
              suppressPaginationPanel={true}
              rowModelType="serverSide"
              rowSelection="single"
              cacheBlockSize={
                this.props.TradeExecutionSummaryStore.AgGridPageSize
              }
              overlayLoadingTemplate={
                this.props.TradeExecutionSummaryStore.GetOverlayLoadingTemplate
              }
              overlayNoRowsTemplate={
                this.props.TradeExecutionSummaryStore.GetOverlayNoRowsTemplate
              }
              onGridSizeChanged={(params) => {
                //If Window Width Greater Than 1585
                if (window.innerWidth > 1585) {
                  //Set Columns to Fit
                  params.api.sizeColumnsToFit();
                }
              }}
              getRowClass={() => this.getRowClass()}
              rowHeight={44}
              headerHeight={44}
              onViewportChanged={(params) => {
                //If Custom Overlay Displayed Then Inject Close and Edit Button
                if (
                  document.querySelectorAll("#agGridCustomOverlayButtonWrapper")
                    .length > 0
                ) {
                  let CustomOverlayButtons = (
                    <div className="agGridCustomOverlayButtonBox">
                      <Button
                        variant="contained"
                        className="agGridCustomOverlayEditBtn"
                        onClick={() =>
                          this.props.TradeExecutionSummaryStore.OpenFiltersOverlay()
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        className="agGridCustomOverlayCloseBtn"
                        onClick={() =>
                          this.props.TradeExecutionSummaryStore.HideOverlay()
                        }
                      >
                        Close
                      </Button>
                    </div>
                  );
                  ReactDOM.render(
                    CustomOverlayButtons,
                    document.querySelector("#agGridCustomOverlayButtonWrapper")
                  );
                }
              }}
              onFirstDataRendered={() => {
                this.firstDataRenderedFunc();
              }}
            ></AgGridReact>
            {this.renderAgGridPagination()}
          </div>
          {this.renderAgGridSpinner()}
        </div>
      );
    }

    return renderData;
  }
}

export default inject("TradeExecutionSummaryStore")(observer(AgGrid));
