import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { observer, inject } from "mobx-react";
import Loader from "~/src/components/Common/Loader";
import SideBar from "~/src/components/TradeExecutionSummary/SideBar";
import TradeExecutionDetails from "~/src/components/TradeExecutionSummary/TradeExecutionDetails";
import FiltersOverlay from "~/src/components/TradeExecutionSummary/FiltersOverlay";
import LogoutOverlay from "~/src/components/TradeExecutionSummary/LogoutOverlay";
import style from "./style.module.css";
import notifications from "~/src/utils/notifications";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    props.TradeExecutionSummaryStore.GetInit();

    //Close All Messages On Mount
    notifications.CloseAllMessages();
  }

  renderPage() {
    return (
      <Grid container spacing={0} className={style.wrapper}>
        <Grid item xs={3} className={`${style.sidebar}`}>
          <SideBar />
        </Grid>
        <Grid item xs={9} className={style.content}>
          <TradeExecutionDetails />
          <FiltersOverlay />
        </Grid>
        <Loader
          IsLoading={this.props.TradeExecutionSummaryStore.RootStore.IsLoading}
        />
        <LogoutOverlay />
      </Grid>
    );
  }

  render() {
    return this.renderPage();
  }
}

export default inject("TradeExecutionSummaryStore")(observer(Dashboard));
